import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Leo Nature
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/leo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Leo</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Leo Nature</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Leo Nature</h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-love");
                  }}>
                  <h5 className="font-semibold text-black">Leo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Leo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-man");
                  }}>
                  <h5 className="font-semibold text-black">Leo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Leo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Leo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Leo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
          <p className="playfair text-black text-xl md:text-3xl">Leo Nature</p><br></br>
          Leo peoples are the stabilizers who form a stable and robust goal or foundation before beginning any actions or tasks. Your energetic and exceptional opinions have a spark in them. Also, you can formulate your thoughts and push out something from your ideas that are unusual and authentic. You are always ready to light a trail, conquer inequity, and hold your pride and reputation. <br></br>
          <br></br>
          In addition to this, you are intelligent about everything. You are a genuine and reliable person. You always have goals, to-do lists, marks, and fancy labels to be fulfilled. You do all actions with flawless planning and organizing and straightforward others on how it should be done. You are a performer at heart and can turn a story, belt a tune, and make others feel slightly hyped up. The quality of being warm-hearted and genuine imbibes superior strength and bravery in you. Due to this, you fiercely encounter all the troubles and hardships in life. You can rightly hook up with the hindrances and loads coming your way with your prowess and determination to be in the lead. You do not desire to rely on others but rather to be relied on. <br></br>
          <br></br>
          <p className="font-bold text-orange-500 inline-block mr-2">Leo:</p>Qualities <br></br>
          It is challenging to woo you romantically. Your fans or soulmates must pull out all the terminations to impress you. When you fall in love, you are deeply devoted and utterly faithful to them. You tend to be overdramatic or overpowering at times. Your arrogant and aloof qualities can make you self-centered and even elitist. You must be receptive to your hearts while sharing things with others. Your inner nature includes the forgiveness and empathy of the soul. You have regular reconciliations in your emotional life as the breaks, and outraged elegance gets sputtered out. <br></br>
          <br></br>
          You are ambitious, fearless, predominant, strong-willed, enthusiastic, self-reliant, self-confident, and self-controlled. The term doubt doesn't exist in your dictionary. You are born bosses. You adopt leadership with the help of or rebellion against the position quo. You tend to give your best and work efficiently when in a dictatorial or command position Charismatic and intrinsic courtesy of mind will bring out the finest of belief in subsidiaries. <br></br>
          <br></br>
          You exactly know where and when to use your vibrancy and creativity to get the everyday things and achieve your resolutions. Hence, you have a strong mind and are uncomplicated. Will your imagination and authority help you to succeed in the year 2024? <br></br>
          <br></br>
          To know about your nature, get your free personalized 2024 yearly report! <br></br>
          <br></br>
          <p className="font-bold text-orange-500 inline-block mr-2">Leo Ruling Planet:</p>The Sun
          The Sun symbolizes the ego, and the masculine energy forces vitality in humans. It represents energy and determination. The power of the planet imbibes intense and emotional warmth in you. Its significance shows intelligence to you. You value being the center of attention. Your friendly and courteous character allows you to be noticed and get their attention. You have many self-images, a drive, and a good feeling. <br></br>
          Conversely, the planet's energy makes you equally dominating and bossy. You can bring warmness, light, and energy into the lives of the individuals around you. The Sun makes you outgoing and self-assured, giving you a massive zest for life. <br></br>
          <br></br>
          <p className="font-bold text-orange-500 inline-block mr-2">Ruling House:</p>Fifth (Pleasure and children)
          The ruling House makes you warm-hearted. You are full of vibrancy as well as active. You feel good at home. The 5th House is all about pleasure, originality, and manifestation. You have playful and flirty ways of love and romance. The place also maintains excellent clues to your romantic life and the tips of love that are essential for you. <br></br>
          Besides, you can get a thorough calculation of your love life with the help of Premium Janampatri. Get your personalized premium Janampatri.
          The House is instantly bonded to children. Hence, the impact of the House imbibes child-like emotions and feelings in your nature. This quality is manifested best when you fool around with children. All self-expression and creativeness stem from this House. Besides, this is the House of romantic love. The fifth House could be called the House of Enjoyment. <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Habits</p><br></br>
          LEO have a big heart and soul and are very helpful. You are also especially mindful and protecting your companions and loved ones. You will continuously support them when they need you. You fear transformation and often take this anxiety to the extreme by grabbing onto the past at all expenditures. This can create an issue for you, and you may even feel stuck if you are in a dead-end connection or an unsatisfying career. You are possible to go overboard and overshare something on sociable media. You surf the internet when you require a distraction. But it brings a lot of your period and impacts your productivity. You often feel unsure about your looks and builds as you want to look your finest at all times. You are likely to obsess over every detail when interacting with other people. You may take every little thing privately and get hurt skillfully. You tend to accept a lot of stress, even if it isn't somebody's intention to hurt you. <br></br>
          <br></br>
          <p className="font-bold text-orange-500 inline-block mr-2">Element:</p>Fire
          It is the second sign ruled by the fire feature. The fiery part makes you magnetic entertainers and natural-born leaders. You own a burning desire to be in the limelight and always shimmer. At the same time, you want to live a bit overeager life and have an authoritative demeanor. You are gregarious and mostly enjoy a group of others. You can make a gathering joyful. You are very confident in your talents and capabilities to get things done. You may even be good at encouraging others. You are goal-oriented, innovative, inspiring, and often set a sample for others. <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Strengths</p><br></br>
          You are honest and innovative leaders and are constantly encouraged to do your best. You are caring, energetic, joyful, and highly optimistic. You possess boundless enthusiasm, the charity of spirit, and the determination to succeed in your career and life. You can make amusing companions, members, and business people. You are impossible to miss as you love to be on the center stage and constantly in the limelight. You are not adept at managing other people. You are a hard worker, very honest, ambitious, and committed; hence, you may often set an example for others. Your direct bravery enables you to win over many people. You love an extensive stage, and it can readily lead to more critical assignments. You are honestly warm-hearted and want everyone around to also be happy. <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Weaknesses</p><br></br>
          You can be impatient, pretentious, and over-dogmatic at times. You usually don't reassess your thoughts. You will not hesitate to use cunning ways, lies, and trickery to discredit your rivals, rivals, and suspicious people. You are luxurious, and money often slips through your fingers. You are inclined to outbursts' temper. You are self-centered and always want to have something done your way. You are capricious and pessimistic. Your passion for admiration often lands you amid backscratchers who can hurt you. You don't take generosity to criticism. Your other auspicious qualities are that you are authoritative, dramatic, and can be very adamant.
          Everyone has exceptional qualities and flaws. What about yours? And how can you be the best? Get your Free janampatri and know your strengths and weaknesses! <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Eminent Personality</p>
          <br></br>
          Barack Obama, Roger Federer, Narayan Murthy, Azim Premji, Melinda Gates, Meg Whitman, Madonna, Jennifer Lopez, J.K. Rowling. Kylie Jenner, Demi Lovato, Halle Berry, Chris Hemsworth, Charlize Theron, Jennifer Lawrence, Bill Clinton, Yves Saint-Laurent, Coco Chanel, Michael Kors, Domenico Dolce, Leonardo DiCaprio, J.K. Rowling, Chris Hemsworth, Saif Ali Khan, Kajol, Dhanush, Randeep Hooda, Arbaaz Khan, Sunil Shetty, Sanjay Dutt, David Dhawan, Mukesh Khanna, Kriti Sanon, Genelia, D'souza, Sridevi, Raageshwari, Manisha Koirala, Saira Banu, Rekha. <br></br>
          <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
